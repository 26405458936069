<template>
	<div>
		<CCard>
			<CCardBody>
				<CRow>
					<CCol col="12" class="py-3">
						<AppointmentForm
							is-admin
							is-update
							class="admin-update-appt"
							@submit-form="updateApptById"
							@cancel-form="goToApptList"
							@delete-form="deleteApptById"
						></AppointmentForm>
					</CCol>
				</CRow>
			</CCardBody>
		</CCard>
	</div>
</template>

<script>
import { GET_APPT_BY_ID, UPDATE_APPT_BY_ID, DELETE_APPT_BY_ID } from "./../store/action-types"
import { createNamespacedHelpers } from "vuex"
const { mapActions } = createNamespacedHelpers("serviceAppointment")

export default {
	name: "AdminUpdateAppt",

	components: {
		AppointmentForm: () => import("./../components/AppointmentForm.vue"),
	},

	props: {
		id: {
			type: [String, Number],
			default: null,
		},
		isAdmin: {
			type: Boolean,
			default: false,
		},
	},

	data() {
		return {}
	},

	computed: {},

	watch: {},

	created() {
		this.GET_APPT_BY_ID({
			id: this.id,
		})
	},

	methods: {
		...mapActions({ GET_APPT_BY_ID, UPDATE_APPT_BY_ID, DELETE_APPT_BY_ID }),

		updateApptById(params) {
			this.UPDATE_APPT_BY_ID(params)
		},

		deleteApptById(params) {
			this.DELETE_APPT_BY_ID(params)
		},

		goToApptList() {
			this.$router.push({ name: "AppointmentManagement" })
		},
	},
}
</script>

<style lang="scss">
@import "@/assets/scss/variables";
@import "@/assets/scss/mixins";
.admin-update-appt {
	.appt-form {
		&__heading {
			margin-bottom: 20px;

			h5 {
				align-self: center;
				margin-right: 15px;
			}
		}

		&__container {
			.form-group,
			.sub-form-group {
				gap: 1%;
				&__label {
					color: inherit;
					font-weight: 500;
					flex: 0 0 100%;
					max-width: 100%;

					@media (min-width: $xs) {
						flex: 0 0 20%;
						max-width: 20%;
					}
					@media (min-width: $ml) {
						flex: 0 0 12%;
						max-width: 12%;
					}
				}

				&__input {
					flex: 0 0 100%;
					max-width: 100%;
					@media (min-width: $xs) {
						flex: 0 0 79%;
						max-width: 79%;
					}
					@media (min-width: $ml) {
						flex: 0 0 35%;
						max-width: 35%;
					}
				}

				.mx-input {
					font-size: 14px !important;
				}
			}

			.form-group {
				&__input {
					@media (min-width: $ml) {
						flex: 0 0 42%;
						max-width: 42%;
					}
				}
				&__label {
					font-size: 14px !important;
				}
			}

			.sub-form-group {
				@media (min-width: $xs) {
					margin-left: 21%;
				}
				@media (min-width: $ml) {
					margin-left: 13%;
				}
				&__label {
					font-size: 14px !important;
				}
			}

			.service .grid {
				grid-template-columns: 1fr;
			}

			input.form-control,
			.mx-input {
				height: 35px !important;
			}

			.multiselect {
				min-height: 32px;
			}

			.status,
			.find-customer,
			.hiv-result {
				/deep/ .multiselect {
					&__select {
						height: 35px;
					}
					&__tags {
						padding-top: 7px;
						height: 35px;
						min-height: 35px;
					}
				}
			}

			.contact {
				height: 35px !important;

				.multiselect {
					width: 95%;
					min-height: 29px !important;
					height: 29px !important;

					&__tags {
						height: 29px !important;
						min-height: 29px !important;
					}
				}
				.option__desc {
					top: 0px;
				}
				.form-control {
					height: 33px !important;
				}
			}
		}

		&__action {
			display: flex;
			justify-content: space-between;

			@media (min-width: $xxs) {
				justify-content: center;

				.btn + .btn {
					margin-left: 40px;
				}
			}
		}
	}
}
</style>
